import {resourceHints, resourceHintsExtra} from './resource-hints'
const CHUNK_NAMES = ['init', 'animations']

export const generateResourceHints = (context, boltBase, rendererModel, serviceTopology, requirejs) => {
    resourceHints(CHUNK_NAMES, context, boltBase, rendererModel, serviceTopology, requirejs)
}

export const generateExtraResourceHints = (context, requirejs, experimentInst) => {
    resourceHintsExtra(context, requirejs, experimentInst)
}

// In SSR (isStreaming) scenario, we can initialize host instance with navigation data from warmup data.
// It will allow loading page json and creating platform worker at an earlier stage.
export const getNavigationModelFromWarmupData = async (isStreaming, isCachedFromSSR, experimentInst) => {
    if (!experimentInst.isOpen('bv_initialize_host_instance_with_navigation_model')) {
        return {}
    }

    // In SSR cache scenario, it makes sense to wait for the SSR cached HTML content to be parsed (populating window.warmupData)
    // before diving into the long CPU task of creating the host instance with partial data.
    if (isStreaming && isCachedFromSSR) {
        if (document.readyState === 'loading') {
            await new Promise(resolve => document.addEventListener('DOMContentLoaded', resolve))
        }
    }

    const warmupData = window.warmupData
    if (!warmupData) {
        return {}
    }

    return {
        prevParsedUrl: warmupData.currentUrl,
        navigationInfos: {primaryPage: warmupData.rootNavigationInfo}
    }
}
